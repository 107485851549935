<template>
  <div id="ingredientView">
    <IngredientSpecifications
      v-if="ingredientsSpecificationData"
      :ingredients-specification-data="ingredientsSpecificationData"
      @loaded="ingredientSpecificationLoaded = true"
    />
  </div>
</template>

<script>
import api from '@/api'
import { mapMutations, mapState } from 'vuex'
import IngredientSpecifications from '@/components/Ingredients/IngredientSpecifications'
import helpers from '@/api/helpers/helpers'
export default {
  name: 'Ingredient',
  components: { IngredientSpecifications },
  data() {
    return {
      ingredientsSpecificationData: null,
      ingredientSpecificationLoaded: false,
    }
  },
  computed: {
    ...mapState({
      pageLoading: (state) => state.pageLoading,
    }),
  },
  watch: {
    ingredientSpecificationLoaded: {
      handler(newVal) {
        if (!newVal) return
        setTimeout(() => {
          this.setPageLoadingFalse()
        }, 300)
      },
    },
  },
  beforeMount() {
    this.loadIngredientView()
  },
  methods: {
    ...mapMutations({
      setPageLoadingFalse: 'setPageLoadingFalse',
    }),
    loadIngredientView() {
      const routeSlug = this.$route.params.ingredientSlug
      this.setIngredientData(
        helpers.readStoredResponse(`view.ingredients.${routeSlug}`)
      )

      api.views.loadIngredient(routeSlug).then((response) => {
        this.setIngredientData(response)
      })
    },
    setIngredientData(response) {
      if (!response || !response.entry) return

      this.ingredientsSpecificationData =
        response.entry.ingredientSpecifications[0] || null
      console.log(response)
    },
  },
}
</script>

<style lang="scss" scoped></style>
