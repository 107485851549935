<template>
  <div class="ingredient-specifications-container">
    <div class="container">
      <div class="image-container">
        <img
          :src="ingredientsSpecificationData.ingredientImage[0].url"
          :alt="ingredientsSpecificationData.ingredientImage[0].title"
        />
      </div>

      <div class="specifications-container">
        <h1>{{ ingredientsSpecificationData.titleText }}</h1>
        <div
          class="text"
          v-html="ingredientsSpecificationData.description"
        ></div>

        <div class="specifications">
          <Accordion
            class="specification"
            v-for="specification in ingredientsSpecificationData.specifications"
            :key="specification.id"
          >
            <template v-slot:header>
              <div class="container accordion-header">
                <h3>{{ specification.titleText }}</h3>
              </div>
            </template>
            <template v-slot:content>
              <div class="container accordion-content">
                <div class="text" v-html="specification.description"></div>
              </div>
            </template>
          </Accordion>
        </div>

        <div class="badges-container">
          <div
            class="badge"
            v-for="badge in ingredientsSpecificationData.badges"
            :key="badge.id"
          >
            <img
              :src="badge.badgeImage[0].url"
              :alt="badge.badgeImage[0].title"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Accordion from '@/components/UI/Accordion'
export default {
  name: 'IngredientSpecifications',
  components: { Accordion },
  props: {
    ingredientsSpecificationData: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.preloadImage()
  },
  methods: {
    preloadImage() {
      const image = document.createElement('img')

      image.onload = () => {
        this.$emit('loaded')
      }

      image.src = this.ingredientsSpecificationData.ingredientImage[0].url
    },
  },
}
</script>

<style lang="scss">
.ingredient-specifications-container {
  @apply my-16;

  .container {
    @apply flex;

    .image-container {
      @apply w-2/5 mr-4;

      img {
        box-shadow: 0 0 16px rgba(25, 49, 83, 0.26);
      }
    }

    .specifications-container {
      @apply w-3/5 ml-10 mt-10;
      color: #193153;

      h1 {
        @apply uppercase text-left mb-4;
        font-family: 'Inter Regular', sans-serif;
        font-size: 2.5em;
      }

      .text {
        @apply text-left w-1/2 mb-6;
        font-family: 'Inter Regular', sans-serif;
        font-size: 0.95em;
      }

      .specifications {
        @apply mr-4 mb-8;
        border-top: 1px solid #202c39;
        border-bottom: 1px solid #202c39;

        .specification {
          &:not(:last-of-type) {
            border-bottom: 1px solid #202c39;
          }

          .accordion-header {
            h3 {
              @apply uppercase py-3;
              font-family: 'Inter SemiBold', sans-serif;
              letter-spacing: 1px;
            }
          }

          .accordion-content {
            .text {
              @apply w-full;

              ul {
                @apply list-disc;
                list-style: disc inside;
              }
              ol {
                @apply list-decimal;
                list-style: decimal inside;
              }
            }
          }
        }
      }

      .badges-container {
        @apply flex;

        .badge {
          &:not(:last-of-type) {
            @apply mr-4;
          }
        }
      }
    }
  }
}
</style>
